import { useLayoutEffect } from "react"
import { signInAnonymously, onAuthStateChanged, initializeAuth, browserLocalPersistence } from "firebase/auth"
import { SiteState } from "./SiteState"
import { app } from "@utils/firebaseConfig"

export const UserAuth = () => {
  useLayoutEffect(() => {
    const auth = initializeAuth(app, {
      persistence: browserLocalPersistence,
      popupRedirectResolver: undefined,
    })
    signInAnonymously(auth)
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorCode, errorMessage)
      })
    onAuthStateChanged(auth, (user) => {
      SiteState.user = user
      // console.log('User', user)
    })
  }, [])

  return null
}