import { Footer } from "@components/site/Footer"
import { Header } from "@components/site/Header"
import { SiteState } from "@components/site/SiteState"
import { UserAuth } from "@components/site/UserAuth"
import { Texture } from "@components/Texture"
import { useGSAP } from "@gsap/react"
import { ScrollRestoration } from "eddev/routing"
import { defineView } from "eddev/views"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { useEffect, useLayoutEffect, useRef } from "react"
import { useRafLoop, useWindowSize } from "react-use"
import { twMerge } from "tailwind-merge"
import { useSnapshot } from "valtio"
import "../utils/firebaseConfig"
import { Toolbar } from "@components/site/Toolbar"
import { VideoPlayer } from "@components/site/VideoPlayer"

gsap.registerPlugin(useGSAP)
gsap.registerPlugin(ScrollTrigger)

export default defineView("_app", (props) => {
  const { isMenuOpen: open, user } = useSnapshot(SiteState)
  const lastHeight = useRef(0)
  const timer = useRef<number>()
  const lastRefresh = useRef(0)

  useRafLoop(() => {
    // sometimes scrolltrigger doesn't detect the correct height right away
    // (maybe it gets it before react has finished rendering?)
    // so we need to manually refresh it when the height changes
    const h = document.body.offsetHeight
    if (Date.now() > (lastRefresh.current + 150) && h !== lastHeight.current) {
      lastHeight.current = h
      ScrollTrigger.refresh()
      lastRefresh.current = Date.now()
    }
  })

  useEffect(() => {
    SiteState.fetchLeaderboardData()
    SiteState.getStats()

    timer.current = window.setInterval(() => {
      SiteState.fetchLeaderboardData()
      SiteState.getStats()
    }, 1000 * 60 * 3) // 3 minutes

    return () => {
      clearInterval(timer.current)
    }
  }, [])

  useEffect(() => {
    if (!user?.uid) return
    SiteState.getHasUserShared()
  }, [user])

  return (
    <>
      <UserAuth />
      <ScrollRestoration />
      <Header />
      <div className={twMerge("w-full relative transition-transform duration-500 bg-black", open ? "translate-y-[100vh] md:-translate-x-1/2 md:translate-y-0" : "")}>
        {props.children}
        <Footer />
        <Toolbar className="relative invisible opacity-0 pointer-events-none pb-0 md:pb-0" />
        {/* <VideoPlayer videoUrl="" /> */}
        <Texture />
      </div>
      <Toolbar />
    </>
  )
})
