import { useEffect, useMemo } from "react"
import { Link, useRoute } from "eddev/routing"
import { useAppData } from "eddev/hooks"
import { MenuItemFieldsFragment, MenuLocationEnum } from "../../types.graphql"
import { twMerge } from "tailwind-merge"

/**
 * Feel free to adjust this file to meet your needs! Keep in mind that you shouldn't
 * add any styling to this file, as it's meant to be a generic component.
 *
 * To style a menu, use styled(Menu), and target the ul, li and a individually.
 */

type MenuItemsProps = {
  items: MenuItemFieldsFragment[]
  className?: string
  depth: number
  linkClassName?: (l: LinkClassNameProps) => string
  onItemClick?: (item: MenuItemFieldsFragment) => void
}

export type LinkClassNameProps = {
  item: MenuItemFieldsFragment
  depth: number
}

function MenuItems(props: MenuItemsProps) {
  const route = useRoute()
  const isExternal = (url?: string | null) => url?.includes("://") && !url.includes(window.location.origin)

  if (props.items) {
    return (
      <ul className={props.className} data-menu-depth={props.depth}>
        {props.items.map((item, i) => (
          <li key={item.label}>
            <Link href={item.url!} target={item.target! || isExternal(item.url) ? "_blank" : undefined} rel={isExternal(item.url) ? "noreferrer noopener" : undefined} className={twMerge(props.linkClassName ? props.linkClassName({ item, depth: props.depth }) : undefined)} onClick={() => props.onItemClick?.(item)}>
              {item.label}
            </Link>
            {item.childItems?.nodes?.length! > 0 && (
              <MenuItems depth={props.depth + 1} items={item.childItems!.nodes as MenuItemFieldsFragment[]} linkClassName={props.linkClassName} />
            )}
          </li>
        )
        )}
      </ul>
    )
  }
  return null
}

type Props = {
  location: keyof typeof MenuLocationEnum
  className?: string
  linkClassName?: (l: LinkClassNameProps) => string
  onItemClick?: (item: MenuItemFieldsFragment) => void
}

export function Menu(props: Props) {
  const menus = useAppData((appData) => appData.menus?.nodes)
  const menu = menus?.find((menu) => menu?.locations?.includes(MenuLocationEnum[props.location]))
  if (process.dev) {
    useEffect(() => {
      if (!menu) {
        console.error(`No menu for location "${props.location}".`)
      }
    }, [menu, props.location])
  }

  if (menu?.menuItems?.nodes) {
    return (
      <MenuItems
        className={props.className}
        depth={0}
        items={menu.menuItems.nodes.filter((p) => !p.parentId) as MenuItemFieldsFragment[]}
        linkClassName={props.linkClassName}
        onItemClick={props.onItemClick}
      />
    )
  } else {
    return null
  }
}
