import { BlockItem } from "@components/atoms/BlockItem"
import { BlockWrapper } from "@components/atoms/BlockWrapper"
import { Button } from "@components/atoms/Button"
import { useSnapshot } from "valtio"
import { SiteState } from "./SiteState"
import { Texture } from "@components/Texture"
import { useRoute, useRouter } from "eddev/routing"
import Marquee from "react-fast-marquee"
import { twMerge } from "tailwind-merge"
import { useLayoutEffect, useState } from "react"
import { Tick } from "@components/atoms/Tick"

export type ToolbarType = "hidden" | "live-index" | "course-progress"

const HowItWorks = ({ open, setOpen }: { open: boolean; setOpen: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const state = useSnapshot(SiteState)
  const started = state.lessonsWatched.length > 0
  const completed = state.lessonsWatched.length === state.lessons.length
  const spread = state.hasUserShared

  return (
    <div onClick={() => setOpen(false)} className={twMerge("fixed left-0 top-0 w-full h-full bg-black bg-opacity-50 z-50 opacity-0 transition-opacity pointer-events-none", open ? "cursor-pointer opacity-100 pointer-events-auto" : "")}>
      <BlockItem className="absolute left-2 md:left-1 bottom-[calc(var(--size-toolbarHeight)+var(--spacing-2)*2)] md:bottom-[calc(var(--size-toolbarHeight)+var(--spacing-1)*2)]" innerClassName="rounded-sm bg-white p-4 w-howItWorksW type-body-xs overflow-hidden m-0 md:m-0 relative">
        <div className="flex flex-col gap-4">
          <div>
            <div className="type-label-m mb-2">Tasks to break your stigma</div>
            <ul className="w-full">
              <li className="flex gap-3 justify-start items-center">
                <Tick active={started} />
                <p>Start the course</p>
              </li>
              <li className="flex gap-3 justify-start items-center">
                <Tick active={completed} />
                <p>Complete the course</p>
              </li>
              <li className="flex gap-3 justify-start items-center">
                <Tick active={spread} />
                <p>Spread the word</p>
              </li>
            </ul>
          </div>
        </div>
        <Texture />
      </BlockItem>
    </div>
  )
}

const Ticker = () => {
  const state = useSnapshot(SiteState)
  const isPlus = (movement: number) => movement > 0
  const isNegative = (movement: number) => movement < 0

  return (
    <div className="absolute left-0 top-0 w-full h-full flex justify-start items-center">
      <Marquee autoFill gradient gradientWidth="2em">
        <div className="flex gap-5 justify-center items-center">
          {state.leaderboardData.items?.map((item, index) => (
            <div key={index} className="flex gap-3 pr-5 border-r border-grey-mid justify-center items-center last:mr-5">
              <div className="type-label-s w-[2em] h-[2em] rounded-full bg-black text-white flex justify-center items-center">{item.rank}</div>
              <p className="type-label-l">{item.name}</p>
              <div className={twMerge("type-label-l flex justify-center items-center gap-3", isPlus(item.movement) ? "text-green" : isNegative(item.movement) ? "text-red" : "text-grey-mid")}>
                <svg className={twMerge(isPlus(item.movement) ? "-scale-y-100" : "", item.movement === 0 ? "hidden" : "")} style={{ width: "0.6em", height: "0.6em" }} viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 6L0.535899 -6.52533e-07L7.4641 -4.68497e-08L4 6Z" fill="currentColor" />
                </svg>
                {item.movement === 0 ? "-" : item.movement}
              </div>
            </div>
          ))}
        </div>
      </Marquee>
    </div>
  )
}

const LiveIndex = ({ setOpen, complete, className }: { setOpen: React.Dispatch<React.SetStateAction<boolean>>; complete: () => void; className?: string; }) => {
  const state = useSnapshot(SiteState)
  const route = useRoute()
  const router = useRouter()
  const isActive = state.toolbarState === "live-index"

  const liveIndexClick = async () => {
    if (route.pathname !== "/") {
      await router.navigate("/")
    }
    SiteState.scrollToLeaderboard()
  }

  return (
    <div className={twMerge("absolute flex flex-col w-full h-full", isActive ? "pointer-events-auto" : "pointer-events-none", className)}>
      <div className={twMerge("w-full relative h-[7vw] mb-3 md:hidden transition-opacity opacity-0", isActive ? "opacity-100" : "")}>
        <Ticker />
      </div>
      <div className="flex flex-row-reverse md:flex-row justify-between items-center gap-5 border-t border-grey-mid pt-3 md:border-none md:pt-0">
        <div className={twMerge("flex gap-3 justify-end md:justify-between w-full transition-opacity opacity-0", isActive ? "opacity-100" : "")}>
          <div className="flex gap-2 justify-center items-center type-label-m md:type-label-l shrink-0 cursor-pointer transition-colors hover:text-grey-dark" onClick={() => liveIndexClick()}>
            <img src={`${env.themePath}assets/images/toolbar/live.png`} alt="Live index" className="w-[2.2em] h-[2.2em] -ml-[1em] animate-pulse" />
            <p>Leaderboard</p>
          </div>
          <div className="hidden w-full relative md:block">
            <Ticker />
          </div>
        </div>
        <div className={twMerge("shrink-0 invisible", isActive ? "visible" : "")}>
          <Button className="md:h-buttonLg hover:bg-orange-light" onClick={() => complete()}>Complete the course</Button>
        </div>
      </div>
    </div>
  )
}

const ProgressStats = () => {
  const state = useSnapshot(SiteState)

  const stats = [
    {
      label: "Total course completions",
      value: state.statsKiwis + state.statsOthers
    },
    {
      label: "Total minutes watched",
      value: state.statsViews * 2
    },
    {
      label: "Total site shares",
      value: state.statsShares
    }
  ]

  return (
    <div className="absolute left-0 top-0 w-full h-full flex justify-start items-center">
      <Marquee autoFill gradient gradientWidth="2em">
        <div className="flex gap-5 justify-center items-center">
          {stats?.map((item, index) => (
            <div key={index} className="flex gap-3 pr-5 border-r border-grey-mid justify-center items-center last:mr-5 type-label-l">
              <p>{item.label}</p>
              <p className="text-green">{item.value.toLocaleString()}</p>
            </div>
          ))}
        </div>
      </Marquee>
    </div>
  )
}

const CourseStats = () => {
  const state = useSnapshot(SiteState)
  let title = "Get started"
  let subtitle = "Tick off your first task!"

  if (state.lessonsWatched.length > 0) {
    title = "Keep it up"
    subtitle = "Awareness looks great on you!"
  }

  if (state.lessonsWatched.length === state.lessons.length) {
    title = "Congratulations"
    subtitle = "Stigma never stood a chance!"
  }

  return (
    <div className="flex gap-7 justify-between md:justify-center items-center shrink-0 type-label-m md:type-label-l">
      <div className="flex flex-col gap-0 justify-center items-start shrink-0">
        <span className="type-label-s text-grey-dark">{title}</span>
        <span className="type-label-m md:type-label-l">{subtitle}</span>
      </div>
      <div className="flex flex-row-reverse items-center justify-end" style={{ fontSize: "1.5em" }}>
        {[...Array(state.lessons.length)].map((_, index) => (
          <Tick key={index} active={state.lessonsWatched.includes(state.lessons.length - index)} className="-ml-[0.25em]" invert />
        ))}
      </div>
    </div>
  )
}

const CourseProgress = ({ setOpen, complete, className }: { setOpen: React.Dispatch<React.SetStateAction<boolean>>; complete: () => void; className?: string; }) => {
  const state = useSnapshot(SiteState)
  const isActive = state.toolbarState === "course-progress"

  return (
    <div className={twMerge("absolute flex flex-col w-full h-full", isActive ? "pointer-events-auto" : "pointer-events-none", className)}>
      <div className={twMerge("w-full relative h-[7vw] mb-3 md:hidden transition-opacity opacity-0", isActive ? "opacity-100" : "")}>
        <div className="absolute w-full left-0 top-0 -mt-2">
          <CourseStats />
        </div>
      </div>
      <div className="flex flex-row-reverse md:flex-row justify-between items-center gap-5 md:gap-6 border-t border-grey-mid pt-3 md:border-none md:pt-0">
        <div className={twMerge("flex gap-3 justify-end md:gap-6 md:justify-between w-full transition-opacity opacity-0", isActive ? "opacity-100" : "")}>
          <div onClick={(() => setOpen(true))} className="cursor-pointer flex gap-2 justify-center items-center type-label-m md:type-label-l shrink-0 transition-colors hover:text-grey-dark">
            <p className="text-[0.9em] md:text-[1em]">Break your stigma</p>
            <svg className="hidden md:block" style={{ width: "2em", height: "2em" }} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M23 16C23 19.866 19.866 23 16 23C12.134 23 9 19.866 9 16C9 12.134 12.134 9 16 9C19.866 9 23 12.134 23 16ZM15.0719 13.3219C14.8156 13.5782 14.4001 13.5782 14.1438 13.3219C13.8876 13.0656 13.8876 12.6501 14.1438 12.3938C15.169 11.3687 16.831 11.3687 17.8562 12.3938C18.8813 13.4189 18.8813 15.081 17.8562 16.1061C17.5127 16.4496 17.0962 16.6787 16.6562 16.7919V17.0937C16.6562 17.4562 16.3624 17.75 16 17.75C15.6376 17.75 15.3438 17.4562 15.3438 17.0937V16.6562C15.3438 16.026 15.8425 15.631 16.2899 15.5303C16.5234 15.4778 16.7452 15.361 16.9281 15.1781C17.4406 14.6655 17.4406 13.8345 16.9281 13.3219C16.4155 12.8093 15.5845 12.8093 15.0719 13.3219ZM16 20.375C16.4832 20.375 16.875 19.9832 16.875 19.5C16.875 19.0168 16.4832 18.625 16 18.625C15.5168 18.625 15.125 19.0168 15.125 19.5C15.125 19.9832 15.5168 20.375 16 20.375Z" fill="currentColor" />
            </svg>
          </div>
          <div className="hidden w-full relative md:block">
            <CourseStats />
          </div>
          <div className="hidden w-full relative md:block">
            <ProgressStats />
          </div>
        </div>
        <div className={twMerge("shrink-0 invisible", isActive ? "visible" : "")}>
          <Button className="md:h-buttonLg hover:bg-orange-light" onClick={() => complete()}>Complete the course</Button>
        </div>
      </div>
    </div>
  )
}

export const Toolbar = ({ className }: { className?: string }) => {
  const state = useSnapshot(SiteState)
  const route = useRoute()
  const router = useRouter()
  const [ready, setReady] = useState(false)
  const [howOpen, setHowOpen] = useState(false)

  const completeTheCourse = async () => {
    if (route.pathname !== "/") {
      await router.navigate("/")
    }
    SiteState.scrollToCourse()
  }

  useLayoutEffect(() => {
    if (state.leaderboardData?.items?.length) return

    setTimeout(() => {
      setReady(true)
    }, 100)
  }, [state.leaderboardData])

  if (route.pathname !== "/") {
    return null
  }

  return (
    <>
      <div className={twMerge("fixed left-0 bottom-0 pb-2 md:pb-1 w-full bg-black z-[101] overflow-hidden translate-y-full duration-500 transition-transform", ready && state.toolbarState !== "hidden" ? "translate-y-0" : "", className)}>
        <BlockWrapper ignoreToolbar>
          <BlockItem className="bg-white h-toolbarHeight" innerClassName="my-4 md:my-3 relative h-full">
            <LiveIndex setOpen={setHowOpen} complete={completeTheCourse} />
            <CourseProgress setOpen={setHowOpen} complete={completeTheCourse} />
          </BlockItem>
        </BlockWrapper>
        <Texture />
      </div >
      <HowItWorks open={howOpen} setOpen={setHowOpen} />
    </>
  )
}