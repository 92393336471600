import { BlockItem } from "@components/atoms/BlockItem"
import { LinkClassNameProps, Menu } from "@components/atoms/Menu"
import { Pill } from "@components/atoms/Pill"
import { HeaderLogo } from "@components/svg/HeaderLogo"
import { Texture } from "@components/Texture"
import { Link, useRoute, useRouter } from "eddev/routing"
import { twMerge } from "tailwind-merge"
import { useSnapshot } from "valtio"
import { SiteState } from "./SiteState"
import { Button } from "@components/atoms/Button"
import { useRef, useState } from "react"
import { useRafLoop } from "react-use"

export type HeaderTheme = "light" | "dark"

const MenuToggle = ({ className, theme }: { className?: string; theme?: HeaderTheme }) => {
  const { isMenuOpen: open } = useSnapshot(SiteState)
  const setOpen = (open: boolean) => (SiteState.isMenuOpen = open)

  return (
    <div onClick={() => setOpen(!open)} className={twMerge("pointer-events-auto group cursor-pointer rounded-md bg-orange flex justify-center items-center text-black transition-colors md:pl-2 hover:bg-orange-light", theme === "dark" ? "bg-black text-orange hover:bg-grey-dark" : "", open ? "open bg-white text-black hover:bg-orange-light" : "", className)}>
      <span className="type-label-m hidden md:block pl-2">{open ? "Close" : "Menu"}</span>
      <div className="flex flex-col justify-center items-center w-menuSize h-menuSize gap-menuGap">
        <div className="w-menuLineW h-menuLineH bg-current transition-all group-[.open]:rotate-45 group-[.open]:translate-y-menuAnimate" />
        <div className="w-menuLineW h-menuLineH bg-current transition-all group-[.open]:opacity-0" />
        <div className="w-menuLineW h-menuLineH bg-current transition-all group-[.open]:-rotate-45 group-[.open]:-translate-y-menuAnimate" />
      </div>
    </div>
  )
}

const MenuOverlay = () => {
  const { isMenuOpen: open } = useSnapshot(SiteState)

  const processMainMenuClassNames = (link: LinkClassNameProps) => {
    const { item, depth } = link
    return twMerge(
      "type-title-l block relative pl-[0.6em] after:w-[0.45em] after:h-full after:absolute after:left-0 after:top-0 after:bg-no-repeat after:bg-contain after:bg-center after:bg-[url('../assets/images/menu/arrow.svg')] mb-4 md:my-4 md:after:opacity-0 md:pl-0 md:transition-all md:hover:pl-[0.6em] md:hover:after:opacity-100 md:after:transition-opacity data-[active=exact]:underline",
    )
  }

  const processInformationMenuClassNames = (link: LinkClassNameProps) => {
    const { item, depth } = link
    return twMerge(
      "type-body-s block py-1 relative pl-[1.1em] after:w-[1em] after:h-full after:absolute after:left-0 after:top-0 after:bg-no-repeat after:bg-contain after:bg-center after:bg-[url('../assets/images/footer/arrow-right.svg')] md:after:opacity-0 md:pl-0 md:transition-all md:hover:pl-[1.1em] md:hover:after:opacity-100 md:after:transition-opacity",
      item.label === "Press Kit" ? "after:bg-[url('../assets/images/footer/download.svg')]" : "",
      item.label?.includes("Donate") ? "after:bg-[url('../assets/images/footer/arrow-up-right.svg')]" : "",
    )
  }

  const closeMenu = () => (SiteState.isMenuOpen = false)

  return (
    <div className={twMerge("pointer-events-auto fixed left-0 top-0 w-full h-full bg-black z-[150] transition-transform duration-500 pb-2 md:left-1/2 md:-ml-1 md:pb-1 md:w-[calc(50%+4px)]", open ? "translate-y-0 md:-translate-x-0" : "-translate-y-full md:translate-x-full md:translate-y-0")}>
      <div className="flex justify-between items-center flex-col h-full">
        <BlockItem className="bg-orange h-full">
          <Pill className="bg-orange-light text-black hidden md:block">Menu</Pill>
          <div className="mt-menuItemsMT">
            <Menu location="Main" linkClassName={processMainMenuClassNames} onItemClick={() => closeMenu()} />
          </div>
        </BlockItem>
        <BlockItem className="bg-beige shrink-0">
          <p className="type-label-m mb-3 block">Information</p>
          <Menu location="Information" linkClassName={processInformationMenuClassNames} onItemClick={() => closeMenu()} />
        </BlockItem>
      </div>
      <Texture />
    </div>
  )
}

export const HeaderTop = () => {
  const { isMenuOpen: open, headerTheme: theme } = useSnapshot(SiteState)
  const router = useRouter()
  const route = useRoute()
  const [hidden, setHidden] = useState(false)
  const lastScroll = useRef(0)

  useRafLoop(() => {
    const scroll = window.scrollY
    const min = window.innerHeight * 0.2

    if (scroll === lastScroll.current) return

    if (scroll > min && scroll > lastScroll.current) {
      setHidden(true)
    } else {
      setHidden(false)
    }
    lastScroll.current = scroll
  })

  const completeTheCourse = async () => {
    if (route.pathname !== "/") {
      await router.navigate("/")
    }
    SiteState.scrollToCourse()
  }

  return (
    <div className={twMerge("fixed left-0 top-0 w-full z-[155] transition-transform duration-500", hidden && !open ? "-translate-y-full" : "")}>
      <div className="mx-6 my-7 md:my-5 md:mx-5">
        <div className="w-full">
          <div className="flex justify-between items-center">
            <Link href="/" className={twMerge("pointer-events-auto w-headerLogo text-orange", open ? "text-black md:text-orange" : "", theme === "dark" ? "text-black md:text-black" : "")} onClick={() => (SiteState.isMenuOpen = false)}>
              <div id="header-top-logo" className="w-full">
                <HeaderLogo className="transition-colors" />
              </div>
            </Link>
            <div className="flex gap-3">
              {/* <Button className={twMerge("bg-orange pointer-events-auto transition-colors hidden md:flex", theme === "dark" ? "bg-black text-orange" : "", open ? "open bg-white text-black" : "")} onClick={() => completeTheCourse()}>
                Complete the course
              </Button> */}
              <MenuToggle theme={theme} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export function Header() {
  const { isMenuOpen: open } = useSnapshot(SiteState)

  return (
    <div className="w-full overflow-hidden pointer-events-none">
      <MenuOverlay />
      <HeaderTop />
    </div>
  )
}
